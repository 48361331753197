.staff_card{
    border: 1px solid rgb(177 177 177 / 21%);
    margin-left: 14px;
    padding: 7px 22px;
    border-radius: 7px;
    margin-top: 7px !important;
    position: relative;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.selected_staff_h5{
    width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    text-transform: capitalize;
}
.hidden-scrollbar {
    overflow: auto;
    scrollbar-width: none;
  }
  
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }
  